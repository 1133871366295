import React from 'react';
import 'assets/css/main.css';

const BuyButton = ({ href, iconImage }) => {
  return (
    <div className="p-buy-area__display-btn">
      <a href={href} className="p-buy-area__display-btn-link">
        <img src={iconImage} alt="" />
      </a>
    </div>
  );
};

export default BuyButton;
