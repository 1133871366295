import React from 'react';
import 'assets/css/main.css';

const ContactCard = ({ href, image }) => {
  return (
    <a href={href} className="l-footer__nav-item-link">
      <img src={image} alt="" aria-hidden="true" />
    </a>
  );
};

export default ContactCard;
