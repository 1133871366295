import React from 'react';

const MainVisual = () => {
  return (
    <div className="p-fv">
      <div className="p-fv__img"></div>
      <div className="p-fv__block">
        <div className="p-fv__logo">
          <img src="images/icon_fv_logo.svg" alt="WLF PROJECT" />
        </div>
        <p className="p-fv__text">WLF PUBLIC SALE</p>
      </div>
    </div>
  );
};

export default MainVisual;
