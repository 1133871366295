import React from 'react';

const ProjectSummary = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">PROJECT SUMMARY</h2>

      <p className="c-text--md u-mt--50">
        ・Our founder Kazu, aka, the “father of the werewolf game” released a
        werewolf game 　app back in 2011 which has 10M downloads and 1.4M MAU.
        <br />
        ・Kazu holds the trademark license for Werewolf/Jinroh Game.
        <br />
        ・Werewolf game has great synergies with Animes/IPs. Kazu has
        collaborated with numerous IPs in the past.
        <br />
        ・The project is invetsed and supported by Japanese top-tier Crypto
        influencers.
        <br />
        ・The Werewolf game is popular all over the world with similar rules but
        different names. (i.g, Mafia game, Les Loups-garous, AmongUs, etc)
        hence, easy to market and acquire users.
        <br />
        ・The game is popular among Youtubers as the game itself is fun to watch
        by audience.
        <br />
        ・We have already raised 2.5M USD all by angel investors so far.
        <br />
        ・We will be releasing a number of mini NFT games before the main game
        release.
        <br />
        ・Our main game, WOLF-FLOW will be a web2 game in the initial phase in
        order to provide easy access to non-crypto users. In the future, we will
        deploy the game onto the blockchain and add more utilities of the WLF
        token.
      </p>
    </section>
  );
};

export default ProjectSummary;
