import React from 'react';
import 'assets/css/main.css';

const Header = () => {
  const items = [
    { href: '', text: 'TOP' },
    { href: '', text: 'PROJECT' },
    { href: '', text: 'TWITTER' },
    { href: '', text: 'WHITE PAPER' },
  ];

  return (
    <header className="l-header">
      <div className="l-header__inner">
        <div className="l-header__block">
          <h1 className="l-header__title">WLF PUBLIC SALE</h1>

          <nav className="l-header__nav u-sp--hide">
            <ul className="l-header__nav-list">
              {items.map((item, i) => (
                <li className="l-header__nav-item" key={i}>
                  <a href={item.href} className="l-header__nav-item-link">
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </nav>

          <div className="l-header__wallet">
            <a href="/" className="l-header__wallet-link">
              CONNECT WALLET
            </a>
          </div>
        </div>

        <nav className="l-header__nav u-pc--hide">
          <ul className="l-header__nav-list">
            {items.map((item, i) => (
              <li className="l-header__nav-item" key={i}>
                <a href={item.href} className="l-header__nav-item-link">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
