import React from 'react';

const Services = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">WLF PROJECT / SERVICES</h2>

      <p className="c-text--sub-ttl-lg u-mt--90">WOLF HUNTER with TERNOA</p>
      <p className="u-mt--40">
        <img src="images/img_07.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        "WOLF HUNTER" is a new NFT game that mixes NFT and social media.
        <br />
        <br />
        With the cooperation of TERNOA, we will create a game where Web3 game
        fans can interact with each other.
        <br />
        <br />
        This will be a psychological game where you decide your actions
        according to the state of the dungeon that changes every day. The
        destiny of each player will be decided by a majority vote of "likes" but
        be careful - everyone is trying to trick the other player with comments
        on social media.
        <br />
        <br />
        Released : 28 Oct. 2023
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">WLF TRIBE</p>

      <p className="u-mt--30">
        <img src="images/img_08.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        "WLF TRIBE" is the 2nd NFT game that mixes NFT and social media.
        <br />
        <br />
        In the game, players can oust the opposing team by reacting and
        supporting their team on Twitter and Discord.
        <br />
        <br />
        But the catch is - the more people on your team, the more points you
        will be deducted! Therefore, players must hide their true team
        affiliation while the opposing team while hiding their true affiliation.
        <br />
        <br />
        The winning team will receive WLF tokens. A psychological battle begins
        via social media.
        <br />
        <br />
        Scheduled release: Winter 2023
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">WLF LAST GENERATION</p>

      <p className="u-mt--40">
        <img src="images/img_09.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        "WLF LAST GENERATION" is our 3rd NFT game that mixes NFT and social
        media.
        <br />
        NFT characters cooperate to battle raid bosses.
        <br />
        <br />
        Scheduled release: Spring 2024
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">WOLF-FLOW</p>

      <p className="u-mt--20">
        <img src="images/img_010.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        Hip-Hop x Werewolf - competing on command.
        <br />
        <br />
        win the trust from other players with dance moves. Since you can play
        using simple commands, even beginners can enjoy the game.
        <br />
        <br />
        This will be an "entertainment werewolf game" that can be enjoyed not
        only by gamers, but also by anyone who appreciates fashion, music, and
        dance culture.
        <br />
        <br />
        Scheduled release: Summer 2024
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">WLF WIN</p>

      <p className="u-mt--30">
        <img src="images/img_011.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        Which AI will win? Predict the outcome based on past records and each
        unique characteristics.
        <br />
        <br />
        This is a game in which AIs fight each other in a game of werewolf and
        the players predict the outcome.
        <br />
        Who will win the game and be the most active MVP player?
        <br />
        <br />
        For Web 3.0 users, the game is played using WLF tokens.
        <br />
        For Web 2.0 users, a free in-game coin, "CAT," will be provided,
        allowing anyone to play for free.
        <br />
        <br />
        "CAT" can’t be converted into cash, but players who perform well will be
        entered into a raffle for WLF tokens and NFTs.
        <br />
        <br />
        Scheduled release: Summer 2024
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">WLF POCKET</p>

      <p className="u-mt--40">
        <img src="images/img_012.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        Users can use their WLF Tokens without gas fees by depositing WLF from
        Metamask into WLF POCKET.
        <br />
        We will continue to expand the utility of our WLF token by partnering
        with various services and companies.
        <br />
        <br />
        Scheduled release: Summer 2024
      </p>
    </section>
  );
};

export default Services;
