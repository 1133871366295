import React from 'react';

const Roadmap = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">ROAD MAP</h2>

      <p className="u-mt--50">
        <img src="images/img_014.jpg" alt="" />
      </p>
    </section>
  );
};

export default Roadmap;
