import React from 'react';

const GameSummary = () => {
  return (
    <section className="u-mt--90 u-mt--80-sp">
      <h2 className="c-text--h2-ttl u-color--w">WHAT IS THE “WEREWOLF GAME”</h2>

      <p className="c-text--md u-mt--50">
        The FAMED Party game where “the humans” compete to hunt down “the
        werewolves (the liars)”.
      </p>

      <p className="u-mt--30">
        <img src="images/img_01.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--20">
        The "Werewolf game" is a party game where players exchange dialogue to
        find the liars.
        <br />
        <br />
        The game played by around 10 people, are dealt cards at the beginning of
        the game which dictate the team they are in; the “humans," who try to
        hunt down the "werewolves," that lie and pretend to be the humans.
      </p>

      <p className="u-mt--50">
        <img src="images/img_02.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--20">
        The game alternates between Night and Day rounds. In the daytime,
        players discuss and expel the most suspicious player.
        <br />
        <br />
        In the night, everyone closes their eyes and perform their night
        actions, while the werewolves choose one human, who then gets expelled
        from the game the next morning.
        <br />
        <br />
        The humans are dealt various roles such as the "Fortune Teller" or
        "Knight" who themselves have their unique abilities that add deepness
        and complexity to the game.
      </p>

      <p className="u-mt--50">
        <img src="images/img_03.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--20">
        The humans win when all werewolves are expelled. Conversely, if the
        number of werewolves exceed the number of humans, the werewolves win.
        <br />
        <br />
        The game has become an entertainment for spectators as well because of
        the finger-biting thrill that entails while watching players
        intelligently and desperately doubting each other. TV shows, stage
        performances, movies, and other werewolf game-themed contents have
        attracted many fans.
      </p>
    </section>
  );
};

export default GameSummary;
