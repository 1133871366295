import React from 'react';

const WlfSummary = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">WHAT IS THE “WLF PROJECT”</h2>

      <p className="c-text--md u-mt--50">
        The holy grail.. The stage for Werewolf Gamers from around the world.
        <br />
        <br />
        <span className="u-db u-fw--900">A New Sport using “DIALOGUE”</span>
        We believe that The Werewolf World Cup will contribute to world peace.
        Why?
        <br />
        <br />
        Until now, international competitions were in non-verbal disciplines
        such as sports and music.
        <br />
        <br />
        However, deep learning in AI has led to dramatic advances in
        multilingual automated translation.
        <br />
        <br />
        If people from around the world could compete through dialogue, people
        would have a better understanding of each other and through
        communication the world would could become a better place. This is our
        ultimate vision.
        <br />
        <br />
        The Werewolf Game is not merely a play on words, but a game where you
        try to build trust and to understand the people you are playing with.
        This is what makes the Werewolf Game so unique.
        <br />
        <br />
        We believe that this will be a new opportunity for Werewolf gamers from
        around the world to understand how others communicate and to get
        accustomed to the different ways in which people think, transcending all
        language barriers.
        <br />
        <br />
        With this vision in mind, we set out first to create a new economy to
        spread this exciting game to the world.
      </p>
    </section>
  );
};

export default WlfSummary;
