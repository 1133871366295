import React from 'react';

const SalesInformation = () => {
  return (
    <section>
      <h2 className="c-text--h2-ttl u-color--w">SALES INFORMATION</h2>

      <p className="c-text--md u-mt--50">
        ・Symbol: WLF
        <br />
        ・Token Type: ERC20
        <br />
        ・Purchase unit price: 1WLF = 0.003 USD
        <br />
        ・Tokens for Sale: 200,000,000
        <br />
        ・Lock-up: 16 months( distribute after 3 months) / Pre-TGE distribution
        10%
        <br />
        ・Available currencies: ETH / USDT(ERC20 or BSC) / BNB
        <br />
        ・FDV: 60,000,000 USD
      </p>
    </section>
  );
};

export default SalesInformation;
