import React from 'react';

const TokenInformation = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">TOKEN INFORMATION</h2>

      <div className="u-mx-width--400 u-mi--auto-sp">
        <p className="u-mt--60">
          <img src="images/img_token.png" alt="" />
        </p>

        <p className="c-text--img-caption u-mt--50">WEREWOLF TOKEN (WLF)</p>
      </div>

      <p className="c-text--sub-ttl-lg u-mt--90">Token Utilities</p>

      <p className="c-text--md u-mt--40">
        Develop an economic zone for Werewolf Game content
        <br />
        Deposit/withdraw to WLF POCKET
        <br />
        Payment solution for collaboration companies
        <br />
        Exclusive sales, events & games
        <br />
        Exclusive alpha/beta test perks
        <br />
        WLF WIN Cheering & tipping
        <br />
        Rewards for content creators (videos, costume, services)
        <br />
        Rewards for active participants
        <br />
        Perks to host games in the community
        <br />
        Support Werewolf Games players & the related organizations
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">Token Information</p>

      <p className="c-text--md u-mt--40">
        Network: ERC 20
        <br />
        Total Supply: 20,000,000,000
        <br />
        Token Name: Werewolf
        <br />
        Symbol: WLF
        <br />
        Role: Governance and Utility tokens
      </p>

      <p className="c-text--sub-ttl-lg u-mt--90">Allocation</p>

      <p className="u-mt--40">
        <img src="images/img_013.jpg" alt="" />
      </p>
    </section>
  );
};

export default TokenInformation;
