import React from 'react';

const Overview = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">WLF PROJECT OVERVIEW</h2>

      <p className="u-mt--50">
        <img src="images/img_06.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--30">
        In the start we will generate sales using "in-game coins" just like
        general smartphone games as crypto is not yet mainstream.
        <br />
        After 2025, we plan to gradually shift to payment by WLF tokens.
      </p>
    </section>
  );
};

export default Overview;
