import React, { useState, useCallback } from 'react';
import BuyButton from 'components/common/BuyButton';
import Timer from 'components/common/Timer';
import {
  expiryDatetime,
  auth,
  minimum,
  maxToken,
  currentToken,
  gaugeMeter,
  unitPrice,
} from 'config/config';

const Sidebar = () => {
  // 購入期限内かどうか
  const [isClosed, setIsClosed] = useState(false);

  const buttons = {
    ETHEREUM: [
      { href: '', iconImage: 'images/icon_btn_eth.png' },
      { href: '', iconImage: 'images/icon_btn_usdt_blue.png' },
    ],
    BSC: [
      { href: '', iconImage: 'images/icon_btn_bnb.png' },
      { href: '', iconImage: 'images/icon_btn_usdt_yellow.png' },
    ],
  };

  // 判定順番
  // 1.ログインしていない状態
  // 2.購入期限が過ぎている場合
  // 3.ログインしている場合
  const buyAreaStatus = () => {
    if (!auth) return 'logout';
    if (isClosed) return 'disable';
    return 'login';
  };

  const handleExpire = useCallback(() => setIsClosed(true), []);

  return (
    <aside className="l-sidebar">
      <div className={'p-buy-area ' + buyAreaStatus()}>
        <div className="p-buy-area__header">
          <p className="p-buy-area__header-img">
            <img src="images/common/icon_sidebar_logo.svg" alt="" />
          </p>
          <p className="p-buy-area__header-text">PUBLIC SALE</p>

          <div className="l-gauge">
            <p className="l-gauge__display">
              <span>{currentToken.toLocaleString()}</span>
              <span> / </span>
              <span>{maxToken.toLocaleString()}</span>
            </p>
            <div className="l-gauge__meter">
              <div className="l-gauge__meter-bg">
                <div
                  className="l-gauge__meter-fg"
                  style={{ width: gaugeMeter + '%' }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-buy-area__display">
          <div className="p-buy-area__display-head">
            <div className="p-buy-area__display-head-group">
              <p className="p-buy-area__display-head-text">MINIMUM</p>
              <p className="p-buy-area__display-head-price">
                <span>$</span>
                <span>{minimum.toLocaleString()}</span>
              </p>
            </div>

            <div className="p-buy-area__display-head-group">
              <p className="p-buy-area__display-head-text">PRICE PRE TOKEN</p>
              <p className="p-buy-area__display-head-price">
                <span>1WLF = $</span>
                <span>{unitPrice}</span>
              </p>
            </div>
          </div>

          <div className="p-buy-area__display-btn-wrap">
            {Object.keys(buttons).map((key) => (
              <div className="p-buy-area__display-btn-group" key={key}>
                <p className="p-buy-area__display-btn-group-text">{key}</p>
                {buttons[key].map((item, i) => (
                  <BuyButton
                    href={item.href}
                    iconImage={item.iconImage}
                    key={i}
                  />
                ))}
              </div>
            ))}

            <div className="p-buy-area__display-btn-group">
              <div className="p-buy-area__display-btn">
                <a href="/" className="p-buy-area__display-btn-link">
                  <img src="images/icon_btn_connect.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="p-buy-area__period">
          <Timer datetime={expiryDatetime} handleExpire={handleExpire} />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
