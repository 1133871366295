import React from 'react';
import Top from 'pages/Top';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import 'assets/css/main.css';

const App = () => {
  return (
    <div className="App">
      <div className="l-wrapper">
        <Header />
        <Top />
        <Footer />
      </div>
    </div>
  );
};

export default App;
