import React from 'react';

const ProjectLeader = () => {
  return (
    <section className="u-mt--90">
      <h2 className="c-text--h2-ttl u-color--w">PROJECT LEADER</h2>

      <div className="u-mx-width--400 u-mi--auto-sp">
        <p className="u-mt--50">
          <img src="images/img_portlate_01.png" alt="" />
        </p>

        <p className="c-text--img-caption u-mt--20">KAZU SUZUKI</p>
      </div>

      <p className="c-text--md u-mt--30">
        Creator of “Werewolf game-nightmare in Prison”
        <br />
        Trademark Holder of "Jinroh-Game (Werewolf Game)" in Japan
        <br />
        Digital artist approved by the Dubai government.
        <br />
        <br />
        In 2011, I released the smartphone app "Werewolf Nightmare in Prison.” I
        have been fascinated by game creators since I was a child and have done
        everything by myself, including design, programming, illustration,
        music, management, and promotion.
        <br />
        <br />
        ＜representative work＞
        <br />
        iOS/Android: "Werewolf Nightmare in Prison"
        <br />
        iOS/Android: "Werewolf Sports Voice Stadium"
        <br />
        DAISO "Werewolf Game Dead or Alive"
        <br />
        Comic "Werewolf Chronicle"
        <br />
        Novel "Werewolf game nightmare of HR"
      </p>

      <p className="u-mt--90">
        <img src="images/img_04.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--50">
        Legendary app that created the werewolf game boom.
        <br />
        This application was made entirely by one person, game creator KAZU
        SUZUKI
        <br />
        <br />
        ・10M DLs
        <br />
        ・1.42M MAU
        <br />
        ・800M total accumulated players
        <br />
        ・1st place in the iOS card game category
        <br />
        ・1st place in the Android card game category
      </p>

      <p className="c-text--sub-ttl-md u-mt--30">
        WEREWOLF NIGHTMARE IN PRISON ( iOS & ANDROID ) TOTAL DL
      </p>

      <p className="u-mt--20">
        <img src="images/img_05.jpg" alt="" />
      </p>
    </section>
  );
};

export default ProjectLeader;
