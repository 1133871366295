import React from 'react';
import MainVisual from 'components/pages/top/MainVisual';
import SalesInformation from 'components/pages/top/SalesInformation';
import ProjectSummary from 'components/pages/top/ProjectSummary';
import GameSummary from 'components/pages/top/GameSummary';
import WlfSummary from 'components/pages/top/WlfSummary';
import ProjectLeader from 'components/pages/top/ProjectLeader';
import Overview from 'components/pages/top/Overview';
import Services from 'components/pages/top/Services';
import TokenInformation from 'components/pages/top/TokenInformation';
import Roadmap from 'components/pages/top/Roadmap';
import Sidebar from 'components/pages/top/Sidebar';

const Top = () => {
  return (
    <main>
      <div className="layer"></div>
      <div className="layer"></div>
      <div className="layer"></div>

      <MainVisual />
      <div className="l-content">
        <div className="l-content__inner">
          <div className="l-content__main">
            <SalesInformation />
            <ProjectSummary />
            <GameSummary />
            <WlfSummary />
            <ProjectLeader />
            <Overview />
            <Services />
            <TokenInformation />
            <Roadmap />
          </div>
          <div className="l-content__side">
            <Sidebar />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Top;
