import React, { useState, useEffect, memo } from 'react';
import { useTimer } from 'react-timer-hook';
import 'assets/css/main.css';

const Timer = ({ datetime, handleExpire }) => {
  const expiryTimestamp = new Date(datetime);

  // 購入期限日が過去かどうか
  const isPast = () => {
    const today = new Date();
    return expiryTimestamp.getTime() < today.getTime();
  };

  // 購入期限が過ぎていた場合
  // useTimerのonExpire内だと画面描画後、非活性になるまでラグがあるため、ここで制御
  useEffect(() => {
    isPast() && handleExpire();
  });

  // 期限日が過ぎているかどうか
  const [isExpire, setIsExpire] = useState(isPast());

  const { days, hours, minutes, seconds } = useTimer({
    expiryTimestamp,
    // 時間制限時の処理
    onExpire: () => {
      setIsExpire(true);
      handleExpire();
    },
  });

  const formatTime = (time) => String(time).padStart(2, '0');

  const PeriodText = () => {
    return (
      <>
        LAST {days}day{days > 1 && 's'}
      </>
    );
  };

  const PeriodTime = () => {
    return (
      <>
        {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
      </>
    );
  };

  return (
    <>
      <p className="p-buy-area__period-text">
        {isExpire ? 'SALES PERIOD' : <PeriodText />}
      </p>
      <p className="p-buy-area__period-time">
        {isExpire ? 'CLOSED' : <PeriodTime />}
      </p>
    </>
  );
};

export default memo(Timer);
